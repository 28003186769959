/* global $ */

class App
{
    bootstrap;

    constructor()
    {
        document.documentElement.style.setProperty('--window-width', document.body.clientWidth + 'px');
        document.documentElement.style.setProperty('--window-height', window.innerHeight + 'px');
        this.disableLogger();
        this.getBootstrap();
        this.initTooltips($('[data-bs-toggle="tooltip"]'));
        this.initToasts($('.toast'));
        this.initForms($('form'));
        this.initFontAwesome();
        this.initMatrixBlocks();
        this.initMenuItems();
        this.initAos();
        this.fileUpload();
        $(window).resize(() => {
            $('.mobile-buttons').css('bottom', 0);
            document.documentElement.style.setProperty('--window-width', document.body.clientWidth + 'px');
            if ($(window).width() > 768) {
                document.documentElement.style.setProperty('--window-height', window.innerHeight + 'px');
            }
        });
        $('body').css('opacity', 1);
        $(window).scroll(() => {
            $('.mobile-buttons').css('bottom', -110);
            $.data(this, 'scrollTimer', setTimeout(function() {
                $('.mobile-buttons').css('bottom', 0);
            }, 3000));
        });
    }

    initMenuItems()
    {
        $('.child-sub-item')
            .on('mouseover', function(){
                let child_div = $(this).attr('showchild');
                $('.child-of-' + child_div).removeClass('d-none');
                $('.short-description').not('.child-of-' + child_div).addClass('d-none');
            });
        $('.dropdown-toggler').each(function() {
            $(this).mouseover(() => {
                $('.dropdown-toggler').not(this).removeClass('show').attr('aria-expanded', 'false');
                $(this).addClass('show').attr('aria-expanded', 'true');
                let actsub = $(this).next('.submenu');
                $('.submenu').not(actsub).removeClass('show').removeAttr('data-bs-popper');
                $(actsub).addClass('show').attr('data-bs-popper', 'none');
            });
        });
        $('.dropdown-hide').each(function() {
            $(this).mouseover(() => {
                $('.dropdown-toggler').removeClass('show').attr('aria-expanded', 'false');
                $('.submenu').removeClass('show').removeAttr('data-bs-popper');
            });
        });
        $('html').click(function(e) {
            if(!$(e.target).hasClass('close-out'))
            {
                $('.dropdown-toggler').removeClass('show').attr('aria-expanded', 'false');
                $('.submenu').removeClass('show').removeAttr('data-bs-popper');
            }
        });
    }

    getBootstrap()
    {
        if (!this.bootstrap) {
            this.bootstrap = import(/* webpackChunkName: "bootstrap" */ 'bootstrap');
        }
        return this.bootstrap;
    }

    disableLogger()
    {
        if (window.Globals.env == 'production') {
            console.log = function() {};
        }
    }

    initMatrixBlocks()
    {
        if ($('.matrix-block').length) {
            import(/* webpackChunkName: "matrixContent" */ './components/matrixContent');
        }
    }

    initFontAwesome()
    {
        if ($('.fa-brands, .fa-solid, .fa-regular, .fa-light, .fa-thin, .fa-duotone').length) {
            import(/* webpackChunkName: "fontawesome" */ './components/fontawesome');
        }
    }

    initForms($forms)
    {
        if ($forms.length) {
            import(/* webpackChunkName: "form" */ './components/form').then(chunk => {
                $.each($forms, (i, elem) => {
                    new chunk.Form($(elem));
                });
            });
        }
    }

    initToasts($elems)
    {
        if ($elems.length) {
            this.getBootstrap().then((bootstrap) => {
                $.each($elems, function (i, elem) {
                    new bootstrap.Toast(elem).show();
                });
            });
        }
    }

    initTooltips($elems)
    {
        if ($elems.length) {
            this.getBootstrap().then((bootstrap) => {
                $.each($elems, function (i, elem) {
                    new bootstrap.Tooltip(elem);
                });
            });
        }
    }

    addAlert(message, $elem, type = "danger", dismissible = false)
    {
        let _class = "alert alert-" + type;
        if (dismissible) {
            _class += ' alert-dismissible fade show';
            message += '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>';
        }
        $('<div class="' + _class + '">' + message + '</div>').appendTo($elem);
    }

    addAlerts(messages, $elem, type = "danger", dismissible = false)
    {
        for (let i in messages) {
            this.addAlert(messages[i], $elem, type, dismissible);
        }
    }

    initAos() {
        $(function () {
            var AOS = require('aos');
            AOS.init({
                offset: 0,
                duration: 700,
                easing: "ease-out-quad",
            });
            window.addEventListener('load', AOS.refresh);
            document.querySelectorAll('img')
                .forEach((img) =>
                    img.addEventListener('load', () =>
                        AOS.refresh()
                    )
                );
        });
    }

    fileUpload() {
        $('.file-upload').each(function() {
            let input = $(this).find('input');
            let label = $(this).find('label');
            $('<span> - Upload File</span>').appendTo(label);
            input.change(() => {
                let file = input.val().split("\\").pop();
                if(file.length > 25){
                    file = file.substr(0,22) + "...";
                }
                label.html(file);
            });
        });
    }
    
}

export default App;
